const lang = localStorage.getItem('i18n') || 'pt';

const resources = {
	pt: {
		menu: {
			products: 'Produtos',
			company: 'Empresa',
			faq: 'FAQ',
			contact: 'Contato'
		},
		products: {
			title: 'Peixe fresco e congelado',
			subtitle: 'Produtos',
			description: 'Com rígido controle de qualidade durante todo o processo, levamos ao mercado o melhor peixe.',
			fish: {
				rm: 'Saramunete',
				pf: 'Budião',
				df: 'Caraúna',
				s: 'Piraúna',
				yt: 'Guaiuba',
				ls: 'Ariocó',
				ms: 'Cioba',
				wg: 'Biquará',
				f: 'Mercador',
				bf: 'Vermelho'
			}
		},
		hero: 'Há mais de 20 anos no mercado com dedicação exclusiva à captura, processamento e fornecimento de peixes para exportação.',
		company: {
			title: "O que nos faz diferentes",
			subtitle: "Sobre Nós",
			body: `
				<p className="mt-8 text-lg text-gray-500">
				Há mais de 20 anos no mercado, a Omega Pescados é uma empresa voltada para a exportação de frutos do mar. Já fornecemos ao mercado europeu e atualmente nos dedicamos à exportação a diferentes regiões dos Estados Unidos.
				</p>
				<p className="mt-2 text-lg text-gray-500">
				Com foco na qualidade, submetemos os nossos pescados a partir do momento de captura às melhores práticas de conservação. Damos orientação e suporte aos nossos parceiros e fornecedores para garantir um padrão de excelência.
				</p>
				<p className="mt-2 text-lg text-gray-500">
				Visando se estabelecer no mercado internacional como fornecedor de produtos frescos, contamos com uma logística eficiente e embarcações próprias que garantem a constância e regularidade da produção.
				</p>
				<p className="mt-2 text-lg text-gray-500">
				Nossos pescados são provenientes de diferentes estados do nordeste do Brasil  e direcionados para uma empresa parceira que conta com a certificação HACCP, além de ser credenciada pelo Ministério da Agricultura no Brasil e FDA nos Estados Unidos.
				</p>
			`
		},
		distribution: {
			title: 'Distribuição e Processamento',
			subtitle: 'Sobre Nós',
			body: `
						<p className="text-lg text-gray-500">
							O processamento dos nossos produtos é realizado em empresas parceiras regulamentadas pelo ministério da agricultura e possuem Certificação Internacional HACCP - Hazard Analysis and Critical Control Point.
						</p>
						<p className="text-lg text-gray-500 mt-5">
							Nossa produção é voltada para o mercado externo, com um volume de venda anual superior a 500 toneladas de peixe e um fornecimento regular ao longo do ano para clientes em diferentes regiões dos Estados Unidos.
						</p>
			`
		},
		faq: {
			title: 'Perguntas frequentes',
			questions: [
				{
					question: 'Qual a data de fundação da empresa?',
					answer: 'A Omega Pescados é uma empresa familiar fundada no Brasil em 2001'
				},
				{
					question: 'Quais os países e regiões que a Omega Pescados atua?',
					answer: 'Atualmente a Omega Pescados exporta para o mercado americano. Abastecendo Nova York, Florida e Geórgia.'
				},
				{
					question: 'Qual a região e origem dos pescados?',
					answer: 'Atlântico Sudoeste, FAO 41'
				},
				{
					question: 'Qual é a prática de pesca realizada?',
					answer: 'Nossos barcos e fornecedores praticam exclusivamente pesca artesanal utilizando covos em embarcações menores que 20 AB.'
				},
				{
					question: 'Como é realizada a conservação dos pescados frescos?',
					answer: 'Para garantir a qualidade e durabilidade do peixe em seu estado fresco, ainda na embarcação os pescados passam por choque térmico sendo conservados em gelo. Matemos um controle rigoroso da temperatura durante todo o processo até o cliente final.'
				},
				{
					question: 'Como garantimos qualidade em nossos produtos?',
					answer: `
					Trabalhamos apenas com indústrias que possuem certificado nacional e internacional para exportação.
					Realizando testes laboratórias para garantir a saúde de nossos pescados e mantendo um rigoroso processo de classificação manual.
					`
				}
			]
		},
		contact: {
			title: 'Fale conosco',
			body: 'Converse com um membro da nossa equipe de vendas. Nós ajudaremos você a escolher os produtos e preços certos para a sua empresa.',
			email: 'omega@omegapescados.com',
			phone: '+55 (81) 3064-7295',
			schedule: 'Segunda-Sexta 8am a 6pm UTC-3'
		}
	},
	en: {
		menu: {
			products: 'Products',
			company: 'Company',
			faq: 'FAQ',
			contact: 'Contact'
		},
		products: {
			title: 'Fresh and frozen fish',
			subtitle: 'Products',
			description: "With a stringent quality control throughout the entire process, we are able to supply the best fish to the market.",
			fish: {
				rm: 'Red Mulet',
				pf: 'Parrot Fish',
				df: 'Doctor Fish',
				s: 'Strawberry Grouper',
				yt: 'Yellow Tail',
				ls: 'Lane Snapper',
				ms: 'Mutton Snapper',
				wg: 'White Grunt',
				f: 'Pork Fish',
				bf: 'Brazilian Snapper'
			}
		},
		hero: 'Over 20 years in the fishing industry with total dedication to the capture, processing and distribution of frozen and fresh fish.',
		company: {
			title: "What make us different",
			subtitle: "About us",
			body: `
            <p className="mt-8 text-lg text-gray-500">
						In the market for over 20 years, Omega Pescados is a company focused on the export of seafood. We have been in the European market and currently we supply different regions of the United States.
            </p>
            <p className="mt-2 text-lg text-gray-500">
						Aiming the highest quality for our fish, we apply the best conservation techniques on our product. We work in collaboration with our partners and suppliers assisting them to ensure our standards are met.
            </p>
            <p className="mt-2 text-lg text-gray-500">
						Focusing on stablishing ourselves as a high-quality fresh product supplier, we rely on a very efficient logistics scheme with our own vessels which ensure constancy in our production throughout the year. 
            </p>
            <p className="mt-2 text-lg text-gray-500">
						Our products come from the northeast region of Brazil and are processed by certified partners that comply with HACCP certification, in addition to being accredited by the Ministry of Agriculture in Brazil and FDA in the United States.
            </p>
			`
		},
		distribution: {
			title: 'Distribution and Processing',
			subtitle: 'About us',
			body: `
						<p className="text-lg text-gray-500">
							The processing of our products is made through partner companies in Brazil. These companies are licensed and inspected by the Brazilian Agriculture Ministry and are Internationally certified with an HACCP (Hazard Analysis and Critical Control Point) which is an internationally recognized method of identifying and managing food safety related risk.
						</p>
						<p className="text-lg text-gray-500 mt-5">
							Our production aims for the foreign market, with an annual sales volume exceeding 500 tons of fish and a regular supply throughout the year to customers in different regions of the United States.
						</p>
			`
		},
		faq: {
			title: 'Frequently asked questions',
			questions: [
				{
					question: "What is the company's founding date?",
					answer: 'Omega Pescados is a family company founded in Brazil at 2001'
				},
				{
					question: 'Which countries and regions does Omega Pescados operate?',
					answer: 'Currently, we export exclusively to the United States. Supplying fish to New York, Florida and Georgia.'
				},
				{
					question: 'What is the region and origin of the fish?',
					answer: 'South West Atlantic, Fishery FAO zone 41'
				},
				{
					question: 'What is the fishing type?',
					answer: 'Our boats and suppliers practice artisanal fishing in vessels smaller than 20 GT.'
				},
				{
					question: 'How do we keep the fish fresh?',
					answer: 'To guarantee the high quality of the product, the fresh fish undergoes thermal shock while still on the vessel and is preserved on ice. We also maintain strict temperature control throughout the entire process from the catch right up to the final customer.'
				},
				{
					question: 'How do we guarantee our product quality?',
					answer: `
					We only work with industries that are nationally and internationally certified.
					Performing laboratory tests to ensure the health of our fish and maintaining a rigorous manual classification.
					`
				}
			]
		},
		contact: {
			title: 'Talk with us',
			body: 'Talk to a member of our sales team. We will help you choose the right products and prices for your business.',
			email: 'omega@omegapescados.com',
			phone: '+55 (81) 3064-7295',
			schedule: 'Monday-Friday 8am to 6pm UTC-3'
		}
	}
}

export default (function i18n() {
	return resources[lang];
})();