import parse from 'html-react-parser';
import i18n from './i18n';

export default function Company() {
  return (
    <div className="relative bg-gray-50" id="empresa">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src="fishes.webp"
            alt="peixes da Omega Pescados"
          />
        </div>
      </div>
      <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            <h2 className="leading-6 text-lightblue font-semibold tracking-wide uppercase">{ i18n.company.subtitle }</h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue sm:text-4xl">
              { i18n.company.title }
            </h3>
            { parse(i18n.company.body) }
          </div>
        </div>
      </div>
    </div>
  )
}