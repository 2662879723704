import i18n from './i18n';

const products = [
	{ name: i18n.products.fish.rm, description: 'PSEUDOPENEUS MACULATUS', imageUrl: 'fishes/saramunete.jpg' },
	{ name: i18n.products.fish.pf, description: 'SPARISOMA SPP', imageUrl: 'fishes/budiao.jpg' },
	{ name: i18n.products.fish.df, description: 'ACHANTURUS SPP', imageUrl: 'fishes/carauna.jpg' },
	{ name: i18n.products.fish.s, description: 'CEPHALOPHOLIS SPP', imageUrl: 'fishes/pirauna.jpg' },
	{ name: i18n.products.fish.yt, description: 'OCYURUS CHRYSURUS', imageUrl: 'fishes/guaiuba.jpg' },
	{ name: i18n.products.fish.ls, description: 'LUTJANUS SYNAGRIS', imageUrl: 'fishes/arioco.jpg' },
	{ name: i18n.products.fish.ms, description: 'LUTJANUS ANALIS', imageUrl: 'fishes/cioba.jpg' },
	{ name: i18n.products.fish.wg, description: 'HAEMULON SPP', imageUrl: 'fishes/biquara.jpg' },
	{ name: i18n.products.fish.f, description: 'ANISOTREMUS VIRGINICUS', imageUrl: 'fishes/frade.jpg' },
	{ name: i18n.products.fish.bf, description: 'LUTJANUS ALEXANDREI', imageUrl: 'fishes/bauna.jpg' }
]

export default function Example() {
	return (
		<div className="bg-white" id="produtos">
			<div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
				<div className="space-y-12">
					<div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
						<h2 className="text-base font-semibold text-lightblue uppercase tracking-wide">{i18n.products.subtitle}</h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue sm:text-4xl">
              { i18n.products.title }
            </h3>
						<p className="text-xl text-gray-500">
							{i18n.products.description}
						</p>
					</div>
					<ul
						role="list"
						className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
					>
						{products.map((product) => (
							<li key={product.name}>
								<div className="space-y-4">
									<div className="aspect-w-3 aspect-h-2">
										<img className="object-cover " src={product.imageUrl} alt={product.name} />
									</div>

									<div className="space-y-2">
										<div className="text-lg leading-6 font-medium space-y-1">
											<h3>{product.name}</h3>
											<p className="text-gray">{product.description}</p>
										</div>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}
