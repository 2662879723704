const active = localStorage.getItem('i18n') || 'pt';

export default function Flag(props) {
	let opacity = ' opacity-50';

	if (props.value === active)
		opacity = '';

	return (
		<img
			className={'hover:opacity-100 cursor-pointer ' + props.className + opacity}
			src={props.src}
			alt={`change website language to #{props.value}`}
			onClick={() => {
				if (props.value !== active) {
					localStorage.setItem('i18n', props.value);
					window.location.reload();
				}
			}}
		/>
	)
}