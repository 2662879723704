import { Fragment } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Popover, Transition } from '@headlessui/react'
import i18n from './i18n';
import Footer from './footer'
import Distribution from './Distribution'
import Faq from './Faq'
import Contact from './Contact'
import Company from './Company';
import Flag from './Flag';
import Products from './Products';

const navigation = [
  { name: i18n.menu.products, href: '#produtos' },
  { name: i18n.menu.company, href: '#empresa' },
  { name: i18n.menu.faq, href: '#faq' },
  { name: i18n.menu.contact, href: '#contato' },
]

export default function App() {
  return (
    <Router>
      <div>
        <div className="relative bg-white overflow-hidden">
          <div className="max-w-7xl mx-auto">
            <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
              <svg
                className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                fill="currentColor"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                aria-hidden="true"
              >
                <polygon points="50,0 100,0 50,100 0,100" />
              </svg>

              <Popover>
                <div className="relative pt-10 lg:pt-20 px-4 sm:px-6 lg:px-8">
                  <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                    <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                      <div className="flex items-center justify-between w-full md:w-auto">
                        <a href="/">
                          <span className="sr-only">omegapescados</span>
                          {/*<img className="h-28 w-auto sm:h-28" src="/logo.png" alt="omega pescados logo" />*/}
                        </a>
                      </div>
                    </div>
                    <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                      {navigation.map((item) => (
                        <HashLink key={item.name} to={item.href} className="text-xl text-gray-500 hover:text-gray">
                          {item.name}
                        </HashLink>
                      ))}
                    </div>

                    <Flag src="br.svg" value="pt" className="h-8 w-auto sm:h-8 ml-20" />
                    <Flag src="us.svg" value="en" className="h-8 w-auto sm:h-8 mx-1" />
                  </nav>
                </div>

                <Transition
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                </Transition>
              </Popover>

              <main className="mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-center">
                  <center><img className="lg:h-60 md:h-60 sm:h-20 w-auto" src="/logo.png" alt="Omega Pescados LTDA" /></center>
                  <p className="text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    {i18n.hero}
                  </p>
                </div>
              </main>
            </div>
          </div>
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img
              className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
              src="hero.webp"
              alt=""
            />
          </div>
        </div>

        <Products />
        <Company />
        <Distribution />
        <Faq />
        <Contact />
        <Footer />
      </div>
    </Router>
  )
}
