import parse from 'html-react-parser';
import i18n from './i18n';

export default function Distribution() {
  return (
    <div className="bg-white overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
        <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
          <div>
            <h2 className="text-base text-lightblue font-semibold tracking-wide uppercase">{i18n.distribution.subtitle}</h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-blue sm:text-4xl">
              {i18n.distribution.title}
            </h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:row-start-1 lg:col-start-2">
            <svg
              className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative text-base mx-auto max-w-prose lg:max-w-none lg:-mt-32">
              <figure>
                <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                  <img
                    className="object-cover object-center"
                    src="logistics.webp"
                    alt="logistica internacional"
                  />
                </div>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="text-base max-w-prose mx-auto lg:max-w-none">
              {parse(i18n.distribution.body)}
            </div>

            <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-4 mt-8">
              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <a href="https://www.gov.br/agricultura/pt-br/assuntos/inspecao/produtos-animal/sif" target="_blank" rel="noreferrer">
                  <img className="h-32 hover:opacity-50 cursor-pointer caret-gray-500" src="sif.svg" alt="S.I.F." />
                </a>
              </div>

              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <a href="https://safefoodalliance.com/food-safety-resources/haccp-overview/" target="_blank" rel="noreferrer">
                  <img className="h-28 hover:opacity-50" src="haccp.svg" alt="haccp certificated" />
                </a>
              </div>

              <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
                <a href="https://www.fda.gov/" target="_blank" rel="noreferrer">
                  <img className="h-28 hover:opacity-50" src="fda.svg" alt="FDA certificated" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
